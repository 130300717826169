<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.adjuntos') }}</h3>
        </div>
        <div class="card-body">
            <TabView :scrollable="true">
                <TabPanel header="Comunicaciones">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in documentosbasicos" :key="archivos">
                            <div v-if="archivos.operarios_idoperario == 0">
                                <Image
                                    v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 4) != '.' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'"
                                    :src='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" + id'
                                    :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                <a v-else
                                    :href='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" + id'
                                    :title="archivos.descripcion">
                                    <img :alt="archivos.descripcion" :src='"https://"+urlglobal+"/images/pdf.gif"'>
                                </a>
                                <div class="row">
                                    <div class="col-md-1">
                                        <input :id="archivos.id_documento"
                                            @click="archivos.checked = !archivos.checked; adjuntararchivo(archivos.checked, archivos.id_documento)"
                                            v-model="archivos.checked" type="checkbox">
                                    </div>
                                    <div class="col-md-10">
                                        <label v-if="archivos.documento.valueOf().length > 15"
                                            :for="archivos.id_documento"> {{ archivos.documento.substring(0, 15) + '...'
                                            }} </label>
                                        <label v-if="archivos.documento.valueOf().length <= 15"
                                            :for="archivos.id_documento"> {{ archivos.documento }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 style="text-align:center">{{ $t('general.subirarchivos') }}</h4>
                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" multiple="true"
                        chooseLabel="Elegir Archivos" />
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <textarea placeholder="Descripcion del fichero" v-model="descrfichero" rows="5"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <button @click="uploadFile()" class="btn btn-success btn-block" type="button">{{
                                $t('general.subirarchivos') }}</button>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel v-for="visita in visitas" :key="visita"
                    :header="new Date(visita.visita.fecha).toLocaleDateString() + ' ' + visita.visita.hora.slice(0, -3) + ' - ' + visita.visita.nombre_operario">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in visita.archivos" :key="archivos">
                            <!---->
                            <Image
                                v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'"
                                :src='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" +id '
                                :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                            <a v-else
                                :href='"https://"+urlglobal+"/mostrar_imagen.php?imagen=" + archivos.documento + "&servicio=" + id  '
                                :title="archivos.descripcion">
                                <img :alt="archivos.descripcion" :src='"https://" +urlglobal+"/images/pdf.gif"'>
                            </a>
                            <div class="row">
                                <div class="col-md-1">
                                    <input :id="archivos.id_documento"
                                        @click="archivos.checked=!archivos.checked; adjuntararchivo(archivos.checked, archivos.id_documento)"
                                        v-model="archivos.checked" type="checkbox">
                                </div>
                                <div class="col-md-10">
                                    <label v-if="archivos.documento.valueOf().length>15" :for="archivos.id_documento">
                                        {{ archivos.documento.substring(0,15)+'...' }} </label>
                                    <label v-if="archivos.documento.valueOf().length <= 15"
                                        :for="archivos.id_documento">
                                        {{ archivos.documento}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <p></p>
                    <h4 style="text-align:center">{{ $t('general.subirarchivos') }}</h4>
                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" multiple="true"
                        chooseLabel="Elegir Archivos" />
                    <p></p>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <textarea placeholder="Descripcion del fichero" v-model="descrfichero" rows="5"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <button
                                @click="uploadFile(visita.visita.operarios_idoperario, visita.visita.idvisitador,visita)"
                                class="btn btn-success btn-block" type="button">
                                {{$t('general.subirarchivos')}}
                            </button>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi'; 
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
export default ({
    props: ['id','visitas','servicio'],
    data() {
        return {
            archivosasubir: [],
            archivo: '',
            descrfichero: '',
            archivosubido: [],
            documentosbasicos: [],   
            urlglobal:''  
        }
    }, 
    setup() {
        return;
    },
    components: {
        TabPanel,TabView,Image,FileUpload
    },
    methods: {
        async documentosServicio() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/datos-basicos
            this.urlglobal = localStorage.getItem('direccion');
           // const api = new PwgsApi;
           //  let datos = await api.get('servicios/' + this.$props.id + '/datos-basicos');
            this.documentosbasicos = this.$props.servicio.documentos.servicio;
        }, 
        async uploadFile(idoperario, idvisita,visita) {
            console.log("visita123", visita);
            //POST  modulos/pwgsapi/index.php/servicios/:id/guardar-documento
            const formData = new FormData();
            if (idoperario>0 && idvisita>0){
            formData.append("id_operario", idoperario);
                formData.append("id_visita", idvisita);
            }
            else {
                formData.append("id_operario", 0);
                formData.append("id_visita", 0); this.mostrarplanning = true;
                this.cargardatos(this.$props.id);
                this.cargarmasdatos();
                this.obtenerEstadosPendientes();
                this.cargarEstadoPendiente();
            }
            formData.append("tipo_documento", 'ficherosvisitas');
            for (let i = 0; i < this.archivosubido.length; i++) {
                formData.append(this.archivosubido[i].name, this.archivosubido[i], this.archivosubido[i].name);
            }
            formData.append("descripcion", this.descrfichero);
            const api = new PwgsApi;
            await api.post('servicios/' + this.$props.id + '/guardar-documento', formData);  
            this.archivosubido = []; 
            this.$parent.cargarvisitas();
            this.documentosServicio();     
        },
        adjuntararchivo(archivo, id) {
            let aux = 0;
            if (archivo == true) {
                if (this.archivosasubir.length > 0) {
                    for (let i = 0; i < this.archivosasubir.length; i++) {
                      if (this.archivosasubir[i] == id) {
                            aux = 1;
                        }
                    }
                }
                if (aux < 1) {
                    this.archivosasubir.push(id);
                    this.$parent.adjarchivo(this.archivosasubir);
                }
            }
            if (archivo == false && this.archivosasubir) { 
                for (let i = 0; i < this.archivosasubir.length; i++) {
                    if (this.archivosasubir[i] == id) {
                        this.archivosasubir.splice(i, 1);
                    }
                }
            }
        },
        subirarchivo(event) {
            for (let i = 0; i < event.files.length;i++){
            this.archivosubido.push(event.files[i]);
            }
        },
    },
    activated() {
        this.documentosServicio();
    },
    watch: {
        id() {  
            this.documentosServicio();
        },
    }
})
</script>  